import React, {useContext} from 'react';
import {Flag} from '@app/model';
import {useTranslation} from 'react-i18next';
import {GreenChoiceMiscSettingsContext} from '@app/context';
import {GreenChoiceIncentiveOption} from '../green-choice-incentive-option/GreenChoiceIncentiveOption';

export function GreenChoiceCustom1Option() {
	const {t} = useTranslation(['greenChoice']);

	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);

	return (
		<GreenChoiceIncentiveOption
			flag={Flag.GREEN_CHOICE_CUSTOM_1}
			isSwitchOn={miscSettingsContext.isCustom1SwitchOn}
			showClosableAlert={true}
			switchLabel={t('greenChoice:settings.miscSettings.incentives.custom1.label')}
		/>
	);
}
