import React from 'react';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, ListItem, Switch, Tooltip, Typography, useTheme} from '@mui/material';
import {
	settingsListItemSx,
	settingsRowLabelSx,
} from '../../../admin/tenant-admin/app-settings/AppSettings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface SettingsListSwitchProps {
	shouldSwitchBeDisabled: boolean;
	isSwitchOn: boolean;
	switchLabel: string | JSX.Element;
	helperText?: JSX.Element;
	handleSwitchChange(): void | Promise<void>;
}

export function SettingsListSwitch(props: SettingsListSwitchProps) {
	const {shouldSwitchBeDisabled, isSwitchOn, switchLabel, handleSwitchChange, helperText} = props;
	const theme = useTheme();

	return (
		<ListItem sx={settingsListItemSx(theme)}>
			<MuiSettingsRow>
				<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<Typography sx={settingsRowLabelSx(theme)}>
						<strong>{switchLabel}</strong>
					</Typography>
					{helperText ? (
						<Tooltip title={helperText} arrow>
							<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
						</Tooltip>
					) : null}
				</Box>
				<Switch
					disabled={shouldSwitchBeDisabled}
					checked={isSwitchOn}
					onChange={handleSwitchChange}
				/>
			</MuiSettingsRow>
		</ListItem>
	);
}
