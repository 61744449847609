import React, {
	useContext,
	useState
} from 'react';
import {UserContext} from '@app/context';
import {
	AuthenticationService,
	CountlyService
} from '@app/services';
import {TenantService} from "@app/services";
import {
	AlertSeverity,
	Authority
} from '@app/model';
import {useAuthorization} from '@app/hooks';
import {SnackbarContext} from '../context/snackbar/SnackbarContext';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';
import {
	Box,
	Button,
	Container,
	Stack
} from '@mui/material';
import '../Machine.css';
import {DesktopViewTitle} from '@app/shared';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import {AxiosResponse} from "axios";

export function Machine() {
	const {t} = useTranslation(['admin']);

	const [isTenantLocked, setIsTenantLock] = useState<boolean>(true);

	const hasViewMachineViewAuthority = useAuthorization(Authority.VIEW_MACHINE_VIEW);

	const {showMessage} = useContext(SnackbarContext);
	const authenticationService = AuthenticationService.get();
	const tenantService = TenantService.get();
	const countly = CountlyService.get();

	const showTriggerNightlyProcessButton = authenticationService.hasAuthority(
		Authority.TRIGGER_NIGHTLY_PROCESS
	);
	const showHotelEmailsButton = authenticationService.hasAuthority(Authority.IMPERSONATE);

	function triggerNightlyProcess() {
		tenantService
			.triggerNightlyProcess()
			.then(() => {
				countly.addEvent('Nightly process manually triggered');
				showMessage(t('admin:nightlyProcessSuccess'), AlertSeverity.SUCCESS);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function logAllActiveEmails() {
		tenantService
			.getHotelEmails()
			.then((hotelEmails: AxiosResponse<string[]>) => {
				countly.addEvent('Get hotel Emails');
				console.log(hotelEmails.data.join(','))
				showMessage('logged emails of all hotels in the console', AlertSeverity.SUCCESS);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function lockTenantButtonClicked() {
		setIsTenantLock((prevState) => !prevState);
	}

	function renderLockTenantButton() {
		const buttonLabel = isTenantLocked ? 'Safe Mode' : 'Dangerous Buttons On';
		const icon = isTenantLocked ? <LockRoundedIcon/> : <LockOpenRoundedIcon/>;

		return (
			<Button
				variant="contained"
				color={isTenantLocked ? 'primary' : 'warning'}
				startIcon={icon}
				onClick={() => lockTenantButtonClicked()}
			>
				{buttonLabel}
			</Button>
		);
	}

	return hasViewMachineViewAuthority ? (
		<div className="App-content">
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="space-between" mb={2}>
					<DesktopViewTitle/>
					{showHotelEmailsButton && <>{renderLockTenantButton()}</>}
				</Box>
				<Box
					p={2}
					display="flex"
					justifyContent="center"
					className={isTenantLocked ? '' : 'danger-background'}
				>
					<Stack spacing={2} maxWidth="400px">
						{showTriggerNightlyProcessButton && (
							<Button
								variant="contained"
								disabled={isTenantLocked}
								onClick={() => triggerNightlyProcess()}>
								Simulate Nightly Process
							</Button>
						)}
						{showHotelEmailsButton && (
							<Button
								variant="contained"
								onClick={() => logAllActiveEmails()}
							>
								Show all active hotel emails
							</Button>
						)}
					</Stack>
				</Box>
			</Container>
		</div>
	) : null;
}
