import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GreenChoiceMiscSettingsContext, SnackbarContext} from '@app/context';
import {Flag} from '@app/model';
import {SettingsListSwitch, SettingsListSwitchWithCloseableAlert} from '@app/shared';

interface GreenChoiceIncentiveOptionProps {
	flag: Flag;
	isSwitchOn: boolean;
	showClosableAlert: boolean;
	alertLabel?: string | JSX.Element;
	switchLabel: string | JSX.Element;
}

export function GreenChoiceIncentiveOption(props: GreenChoiceIncentiveOptionProps) {
	const {flag, showClosableAlert, alertLabel, isSwitchOn, switchLabel} = props;
	const {t} = useTranslation(['greenChoice']);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);
	const {showMessage} = useContext(SnackbarContext);

	async function handleChange() {
		setIsLoading(true);
		await miscSettingsContext.handleSwitchChange(flag, isSwitchOn, showMessage);
		setIsLoading(false);
	}

	return showClosableAlert && alertLabel ? (
		<SettingsListSwitchWithCloseableAlert
			label={switchLabel}
			alertLabel={alertLabel}
			shouldDisableSwitch={isLoading}
			isSwitchOn={isSwitchOn}
			handleSwitchChange={handleChange}
		/>
	) : (
		<SettingsListSwitch
			shouldSwitchBeDisabled={isLoading}
			isSwitchOn={isSwitchOn}
			switchLabel={switchLabel}
			handleSwitchChange={handleChange}
		/>
	);
}
