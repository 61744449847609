import React, {useState} from 'react';
import {Box, IconButton, ListItem, Switch, Typography, useTheme} from '@mui/material';
import {
	settingsListItemSx,
	settingsRowLabelSx,
} from '../../../admin/tenant-admin/app-settings/AppSettings';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {CloseableAlert} from '@app/shared';
import {AlertSeverity} from '@app/model';

interface SettingsListSwitchWithCloseableAlertProps {
	label: string | JSX.Element;
	alertLabel: string | JSX.Element;
	shouldDisableSwitch: boolean;
	isSwitchOn: boolean;
	handleSwitchChange(): void;
}

export function SettingsListSwitchWithCloseableAlert(
	props: SettingsListSwitchWithCloseableAlertProps
) {
	const {label, alertLabel, isSwitchOn, shouldDisableSwitch, handleSwitchChange} = props;
	const theme = useTheme();

	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

	return (
		<>
			<ListItem sx={settingsListItemSx(theme)}>
				<MuiSettingsRow>
					<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<Typography sx={settingsRowLabelSx(theme)}>
							<strong>{label}</strong>
						</Typography>
						<IconButton
							sx={{cursor: 'pointer', ml: 0.5}}
							onClick={() => setIsAlertOpen((prevState) => !prevState)}
						>
							<HelpOutlineRoundedIcon color="primary" fontSize="small" />
						</IconButton>
					</Box>
					<Switch
						disabled={shouldDisableSwitch}
						checked={isSwitchOn}
						onChange={handleSwitchChange}
					/>
				</MuiSettingsRow>
			</ListItem>
			<CloseableAlert
				open={isAlertOpen}
				setOpen={setIsAlertOpen}
				severity={AlertSeverity.INFO}
				label={alertLabel}
			/>
		</>
	);
}
