import React from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Typography,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import {
	GreenChoiceIncentiveType,
	ResolvedLanguage
} from '@app/model';
import Radio from '@mui/material/Radio';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {GreenChoiceIncentiveTypeEditor} from "../../../../../../model";
import {BubbleEditorWithOutline} from "../../../../settings/green-choice-custom-description-card/bubble-editor-with-outline/BubbleEditorWithOutline";

interface GreenChoiceActivationPageIncentiveSelectorProps {
	activeIncentiveTypes: GreenChoiceIncentiveTypeEditor[];
	formikProps: FormikProps<{
		lastName: string;
		incentiveType: undefined | GreenChoiceIncentiveType;
	}>;
	language: ResolvedLanguage;
	isEditMode: boolean;
}

export function GreenChoiceActivationPageIncentiveSelector(
	props: GreenChoiceActivationPageIncentiveSelectorProps
) {
	const {t} = useTranslation(['greenChoice']);
	const {activeIncentiveTypes, formikProps, language} = props;

	function editorOrText(incentiveTypeEditor: GreenChoiceIncentiveTypeEditor) {
		if (props.isEditMode) {
			return <BubbleEditorWithOutline
				initialValue={incentiveTypeEditor.text ? incentiveTypeEditor.text : ''}
				onChange={
					(value: string) => {
						if (incentiveTypeEditor.setter) {
							incentiveTypeEditor.setter(value)
						}
					}
				}
			/>
		} else {
			return incentiveTypeEditor.text;
		}
	}

	return (
		<Box sx={{width: '100%', display: 'flex'}}>
			<FormControl error={Boolean(formikProps.errors.incentiveType)}>
				<FormLabel sx={{textAlign: 'left'}}>
					{t('greenChoice:guestView.activationPage.incentive.label', {lng: language})}
				</FormLabel>
				<RadioGroup
					name="incentiveType"
					onChange={formikProps.handleChange}
					defaultValue={GreenChoiceIncentiveType.DONATION}
				>
					{activeIncentiveTypes.map((incentiveTypeEditor, i) => {
							function getIncentiveTypeLabel() {
								switch (incentiveTypeEditor.type) {
									case GreenChoiceIncentiveType.CUSTOM_1:
									case GreenChoiceIncentiveType.VOUCHER_DIGITAL:
									case GreenChoiceIncentiveType.VOUCHER_PHYSICAL:
										return editorOrText(incentiveTypeEditor);
									default:
										return t(
											`greenChoice:guestView.activationPage.incentive.${incentiveTypeEditor.type}.label`
										)
								}
							}

							return (
								<FormControlLabel
									sx={{
										display: 'flex',
										alignItems: 'start',
										mt: 2,
										textAlign: 'left',
									}}
									key={`${incentiveTypeEditor}-${i}`}
									value={incentiveTypeEditor}
									control={<Radio size="small" sx={{mt: -1.2}} disabled={props.isEditMode}/>}
									label={
										<Typography fontSize="small">
											{getIncentiveTypeLabel()}
										</Typography>
									}
								/>
							)
						}
					)}
				</RadioGroup>
				<FormHelperText>{formikProps.errors.incentiveType}</FormHelperText>
			</FormControl>
		</Box>
	);
}
