import {AbstractService} from './AbstractService';
import axios, {AxiosResponse} from 'axios';
import {
	assignmentData,
	AssignmentData,
	availableTenants,
	Feature,
	Flag,
	hotelEmails,
	superAdminFeature,
	superAdminFlag,
	superAdminSettings,
	TaskTrigger,
	taskTrigger,
	TaskTriggerMode,
	Tenant,
	tenantAdmin,
	tenantAdminFeature,
	tenantAdminFlag,
	tenantAdminLanguage,
	tenantAppSettings,
	tenantBedLinenInterval,
	TenantChoiceView, triggerNightly,
} from '@app/model';

export class TenantService extends AbstractService {
	static INSTANCE = new TenantService();
	cancelTokenSource = axios.CancelToken.source();

	async getTenant() {
		return axios.get(this.getUrl(tenantAdmin), {
			cancelToken: this.cancelTokenSource.token,
		});
	}

	async getAvailableTenants(): Promise<AxiosResponse<TenantChoiceView[]>> {
		return axios.get(this.getUrl(availableTenants));
	}

	async chooseTenant(tenantId: string): Promise<AxiosResponse<void>> {
		return axios.put(this.getUrl(availableTenants + "/" + tenantId));
	}

	async updateTenant(tenant: Tenant) {
		return axios.put(this.getUrl(tenantAdmin), tenant);
	}

	async getAppSettings() {
		return axios.get(this.getUrl(tenantAppSettings));
	}

	async setTenantLanguage(language: string) {
		return axios.put(this.getUrl(tenantAdminLanguage), null, {params: {language: language}});
	}

	async addFeature(feature: Feature) {
		return axios.post(this.getUrl(tenantAdminFeature) + '/' + feature);
	}

	async deleteFeature(feature: Feature) {
		return axios.delete(this.getUrl(tenantAdminFeature) + '/' + feature);
	}

	async addFlag(flag: Flag) {
		return axios.post(this.getUrl(tenantAdminFlag) + '/' + flag);
	}

	async deleteFlag(flag: Flag) {
		return axios.delete(this.getUrl(tenantAdminFlag) + '/' + flag);
	}

	async updateLinenInterval(newInterval: number) {
		const config = {params: {interval: newInterval}};
		return axios.put(this.getUrl(tenantBedLinenInterval), {}, config);
	}

	async getSuperAdminSettings(tenantId: string) {
		return axios.get(this.getUrl(superAdminSettings) + '/' + tenantId);
	}

	async addFlagAsSuperAdmin(flag: Flag, tenantId: string) {
		return axios.post(this.getUrl(superAdminFlag) + '/' + flag + '/' + tenantId);
	}

	async deleteFlagAsSuperAdmin(flag: Flag, tenantId: string) {
		return axios.delete(this.getUrl(superAdminFlag) + '/' + flag + '/' + tenantId);
	}

	async addFeatureAsSuperAdmin(feature: Feature, tenantId: string) {
		return axios.post(this.getUrl(superAdminFeature) + '/' + feature + '/' + tenantId);
	}

	async deleteFeatureAsSuperAdmin(feature: Feature, tenantId: string) {
		return axios.delete(this.getUrl(superAdminFeature) + '/' + feature + '/' + tenantId);
	}

	async getTaskTriggers(): Promise<AxiosResponse<TaskTrigger[]>> {
		return axios.get(this.getUrl(taskTrigger));
	}

	async setTrigger(trigger: TaskTrigger): Promise<AxiosResponse<void>> {
		return axios.put(this.getUrl(taskTrigger), trigger);
	}

	async deleteTrigger(mode: TaskTriggerMode): Promise<AxiosResponse<void>> {
		return axios.delete(this.getUrl(taskTrigger) + mode);
	}

	async getAssignmentData(includeHeadCleaners: boolean): Promise<AssignmentData> {
		const config = {
			params: {
				includeHeadCleaners: includeHeadCleaners,
			},
		};
		return axios.get(this.getUrl(assignmentData), config);
	}

	async triggerNightlyProcess() {
		return axios.post(this.getUrl(triggerNightly));
	}

	async getHotelEmails(): Promise<AxiosResponse<string[]>> {
		return axios.get(this.getUrl(hotelEmails));
	}
}
