import React, {useContext} from 'react';
import {Flag} from '@app/model';
import {GreenChoiceMiscSettingsContext} from '@app/context';
import {GreenChoiceIncentiveOption} from '../green-choice-incentive-option/GreenChoiceIncentiveOption';
import {useTranslation} from "react-i18next";

export function GreenChoiceNoIncentiveOption() {
	const {t} = useTranslation(['greenChoice']);
	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);

	return (
		<GreenChoiceIncentiveOption
			flag={Flag.GREEN_CHOICE_NO_INCENTIVE}
			isSwitchOn={miscSettingsContext.isNoIncentiveSwitchOn}
			showClosableAlert={false}
			switchLabel={t('greenChoice:settings.miscSettings.incentives.none.toggle')}
		/>
	);
}
